import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  GatsbyLocationObjectType,
  locationType,
  locationRegionGroupsType,
  seoType,
} from "../types"
import { Header, Layout, SEO, SignOffStillLooking } from "../components"
import { locationGroupSlug } from "../utilities"

interface Props {
  location: GatsbyLocationObjectType
  data: locationRegionGroupsType & {
    contentfulLocationGroups: {
      slug: string
      contentful_id: string
      name: string
      description: {
        raw: string
      }
      courseTopic: {
        contentful_id: string
      }
      seo: seoType
    }
  }
}

export default ({ data }: Props) => {
  const url =
    process.env.GATSBY_SITE_URL +
    locationGroupSlug(data.contentfulLocationGroups.slug)

  const courseTopicId = data.contentfulLocationGroups.courseTopic.contentful_id

  const locationHasBookingsForCourseTopic = ({
    courseLocation,
    courseTopicId,
  }: {
    courseLocation: locationType
    courseTopicId: string
  }) => {
    if (
      courseLocation.course___bookings &&
      courseLocation.course___bookings.length > 0 &&
      courseLocation.course___bookings.some((booking) =>
        booking.courses.some((course) => {
          if (course.course___topics) {
            return course.course___topics.some(
              (topic) => topic.contentful_id === courseTopicId
            )
          } else {
            console.log("No course topic found", course)
          }
        })
      )
    ) {
      return true
    } else {
      return false
    }
  }

  // Filtering all course locations and only keeping region groups if location pages exist within them for the correct courseTopicId
  const locationsRegionGroups =
    data.allContentfulLocationCityRegionsRegionGroups.edges.filter(
      ({ node }) =>
        node.regions &&
        node.regions.length > 0 &&
        node.regions.some(
          (region) =>
            region.location___city &&
            region.location___city.length > 0 &&
            region.location___city.some(
              (city) =>
                city.location &&
                city.location.length > 0 &&
                city.location.some((location) =>
                  locationHasBookingsForCourseTopic({
                    courseLocation: location,
                    courseTopicId: courseTopicId,
                  })
                )
            )
        )
    )

  const sortCitiesAlphabetically = (
    a: {
      contentful_id: string
      location: Array<locationType>
    },
    b: {
      contentful_id: string
      location: Array<locationType>
    }
  ) => {
    if (a.location[0].city.name < b.location[0].city.name) {
      return -1
    }
    if (a.location[0].city.name > b.location[0].city.name) {
      return 1
    }
    return 0
  }

  /* 
    NOTES
    -----
    1. Location group pages use Location > City > Regions > Region Groups to find Location > City > Regions, then Location > City, then, Location
    2. Location's are Location pages added directly to bookings in Contentful
    3. On Location group pages all bookings are retrived from Contentful and looped through to match bookings with cities to check if any bookings exist
  */

  return (
    <Layout>
      <SEO
        title={data.contentfulLocationGroups.seo.title}
        description={data.contentfulLocationGroups.seo.description.description}
        image={data.contentfulLocationGroups.seo.socialImage?.file?.url}
        url={url}
      />
      <Header
        heading={data.contentfulLocationGroups.name}
        headerIntroduction={data.contentfulLocationGroups.description}
      />
      <main className="backgroundGreyLightSuper">
        <section className="wrapper padded">
          {locationsRegionGroups.map(({ node }) => {
            const regions = node.regions.filter((region) =>
              region.location___city.some((city) =>
                city.location.some((location) =>
                  locationHasBookingsForCourseTopic({
                    courseLocation: location,
                    courseTopicId: courseTopicId,
                  })
                )
              )
            )

            return (
              <div className="locationGroup" key={node.contentful_id}>
                <h2 className="headingMedium">{node.name}</h2>
                {regions &&
                  regions.length > 0 &&
                  regions.map((locationRegion) => {
                    const cities = locationRegion.location___city.sort(
                      sortCitiesAlphabetically
                    )
                    return (
                      <section
                        key={locationRegion.contentful_id}
                        className="locationGroupRegion"
                      >
                        <h3 className="boldText">{locationRegion.name}</h3>
                        <div className="locationGroupRegionLocations">
                          {cities &&
                            cities.length > 0 &&
                            cities.map((city) => (
                              <React.Fragment key={city.contentful_id}>
                                {city.location &&
                                  city.location.length > 0 &&
                                  city.location.map(
                                    (location) =>
                                      locationHasBookingsForCourseTopic({
                                        courseLocation: location,
                                        courseTopicId: courseTopicId,
                                      }) && (
                                        <Link
                                          key={location.contentful_id}
                                          to={`/locations/${location.slug}`}
                                          className="locationGroupRegionLocation"
                                        >
                                          <GatsbyImage
                                            className="locationGroupRegionLocationImage"
                                            image={
                                              location.city.image
                                                .gatsbyImageData
                                            }
                                            alt={location.city.image.title}
                                          />
                                          <span className="locationGroupRegionLocationName">
                                            {location.city.name}
                                          </span>
                                        </Link>
                                      )
                                  )}
                              </React.Fragment>
                            ))}
                        </div>
                      </section>
                    )
                  })}
              </div>
            )
          })}
        </section>
      </main>
      <SignOffStillLooking />
    </Layout>
  )
}

export const pageQuery = graphql`
  query getLocationGroupData($contentful_id: String!) {
    allContentfulLocationCityRegionsRegionGroups(
      sort: { order: DESC, fields: name }
    ) {
      edges {
        node {
          regions {
            location___city {
              location {
                contentful_id
                slug
                city {
                  name
                  image {
                    gatsbyImageData
                    title
                  }
                  contentful_id
                }
                course___bookings {
                  courses {
                    course___topics {
                      name
                      contentful_id
                    }
                  }
                }
              }
              contentful_id
            }
            name
            contentful_id
          }
          name
          contentful_id
        }
      }
    }
    contentfulLocationGroups(contentful_id: { eq: $contentful_id }) {
      slug
      contentful_id
      name
      description {
        raw
      }
      courseTopic {
        contentful_id
      }
      seo {
        description {
          description
        }
        socialImage {
          file {
            url
          }
        }
        title
      }
    }
  }
`
